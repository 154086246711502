<template>
  <main class="tutorialContainer">
    <header>
      <h2>Tutorials</h2>
    </header>
    <section class="tutorials">
      <TutorialCard />
      <TutorialCard />
      <TutorialCard />
      <TutorialCard />
      <TutorialCard />
    </section>
  </main>
</template>
<script>
import TutorialCard from "./TutorialCard";
export default {
  name: "TutorialList",
  components: { TutorialCard },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables";

.tutorialContainer {
  width: 90%;
  height: 40rem;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

header {
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  & h2 {
    font-family: $primaryFont;
    background: none;
    margin: 0;
    padding: 0;
  }
}

.tutorials {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
</style>