<template>
  <main>
    <img src="@/assets/placeholder.jpg" alt="backgroundThumbnail" />
    <div class="tutorialLabel">
      <p>Tutorial Title</p>

      <div class="tutorialMeta">
        <div class="length"><i class="fas fa-clock"></i> 3:12</div>
        <div class="tutorialIcons">
          <i class="fab fa-js"></i>
          <i class="fab fa-vuejs"></i>
          <i class="fab fa-node"></i>
        </div>
        <div class="difficulty">
          <i class="fas fa-circle"></i>
          <i class="far fa-circle"></i>
          <i class="far fa-circle"></i>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "TutorialCard",
};
</script>
<style lang='scss' scoped>
@import "@/styles/variables";

main {
  width: 24rem;
  height: 13.5rem;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  margin: 1rem 2rem 2rem 0;

  & img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  & .tutorialLabel {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;

    & p {
      width: 100%;
      font-family: $primaryFont;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
    }

    & i {
      margin-right: 0.5rem;
      font-size: 1rem;
    }

    & .tutorialMeta {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & .tutorialIcons {
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        & i {
          font-size: 1.5rem;
        }
      }

      & .length {
        width: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      & .difficulty {
        width: 20%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        & i {
          color: teal;
        }
      }
    }
  }
}
</style>